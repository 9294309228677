import "./index.css";
import React from "react";
import { Helmet } from "react-helmet";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar";
import SupportedBy from "./components/SupportedBy";
import Products from "./components/Products";
import Introduction from "./components/Introduction";
import Introduction2 from "./components/Introduction2";
import FormBlock from "./components/FormBlock";

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Learn, Share, Connect | HackHub</title>
        <meta
          name="description"
          content="Our products create an ecosystem where developers, artists, investors, and entrepreneurs learn cutting-edge technologies, share project experience, and connect with communities."
        />
      </Helmet>
      <div className="modal"></div>
      <NavBar
        btnOnClick={() => {
          elementScrollIntoView(document.querySelector(".form-block"), {
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }}
      />

      <div
        style={{ height: "100%", overflowY: "scroll" }}
        onScroll={(p1, p2, p3) => {
          console.log("scroll", p1, p2, p3);
        }}
      >
        <Introduction />
        <Products />
        <SupportedBy />
        <Introduction2 />
        <FormBlock />
        <Footer />
      </div>
    </>
  );
}

export default HomePage;
