import "./TalentFooter.css";
import { useNavigate } from "react-router-dom";

function TalentFooter() {
  const navigate = useNavigate();

  return (
    <div className="container-1">
      <div className="talent-foot-container">
        <div className="talent-foot-img">
          <img
            src="/images/talent_presentation_1.svg"
            alt="presentation HackHub Talent"
          />
        </div>
        <div className="talent-foot">
          <div className="content">
            Learn more about our product and services
          </div>
          <button
            className="btn"
            onClick={() => {
              // window.location.href="/talent/demo";
              navigate("/talent/demo");
            }}
          >
            Get a Demo
          </button>
        </div>
      </div>
    </div>
  );
}

export default TalentFooter;
