import React, { useState } from "react";
import "./index.css";

import Select from "../DemoForm/components/MySelect";
import { formatQuoteFormSendData, validateData } from "../../util";
import { emailSend } from "../../service";

const requiredFields = [
  "demoType",
  "name",
  "email",
  "organization",
  "organizationIdentity",
];

const initialFormData = {
  demoType: "Event Quote",
  name: "",
  email: "",
  organization: "",
  subscribe: false,
};

export default function QuoteModalForm(props) {
  const { visible = false, handleVisible } = props;

  const [submitting, setSubmitting] = useState(false);

  const [formData, setFormdata] = useState(initialFormData);

  const onFormDataChange = (e) => {
    // console.log({ [e.target.name]: e.target.value });
    setFormdata({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onCheckboxChange = (e) => {
    // console.log({ [e.target.name]: e.target.value });
    setFormdata({
      ...formData,
      [e.target.name]: !formData[e.target.name],
    });
  };

  const onFormDataSelect = (option, action) => {
    // console.log(option, action);
    // console.log({ [action.name]: option.value });
    setFormdata({
      ...formData,
      [action.name]: option.value,
    });
  };

  // const onProductSelect = (index) => {
  //   // console.log("product", index);
  //   setFormdata({
  //     ...formData,
  //     demoType: productsName[index],
  //   });
  // };

  const handleSubmit = () => {
    setSubmitting(true);
    // console.log(formData);
    if (validateData(formData, requiredFields)) {
      // console.log(formatQuoteFormSendData(formData));
      // setSubmitting(false);
      emailSend(
        formatQuoteFormSendData(formData),
        // success
        () => {
          alert("Thanks for your interests. We will contact you soon.");
          setFormdata(initialFormData); // COULD DELETE THIS LINE
          setSubmitting(false);
          window.location.reload();
        },
        // fail or error
        () => {
          alert(
            "Woops. Something wrong. Please try to email or call us directly."
          );
          setSubmitting(false);
        }
      );
    } else {
      // not validated
      alert("Please fill all required fields.");
      setSubmitting(false);
    }
  };

  return (
    <div
      className="modal-form"
      hidden={!visible}
      // onClick={() => handleVisible(false)}
    >
      <div
        className="modal-form-close-icon"
        onClick={() => {
          handleVisible(false);
        }}
      ></div>

      <div className="demo-form">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            return false;
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter") e.preventDefault();
          }}
        >
          <h1 className="title">Get a Quote </h1>
          <div className="form-fields row">
            {/* <div className="input-width-12">
              <label>
                *Product
                <div className="form-product-options-row">
                  {productsName.map((productName, index) => (
                    <div
                      key={index}
                      className={`product-option  + ${
                        formData.demoType === productsName[index]
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => onProductSelect(index)}
                    >
                      {productName}
                    </div>
                  ))}
                </div>
              </label>
            </div> */}

            <div className="input-width-12">
              <label>
                *Name
                <input
                  className="input-width-12"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={onFormDataChange}
                  placeholder="Enter your name"
                />
              </label>
            </div>

            <div className="input-width-12">
              <label>
                *Email Address
                <input
                  className="input-width-12"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={onFormDataChange}
                  placeholder="Enter your work email address"
                />
              </label>
            </div>

            <div className="input-width-12">
              <label>
                *Organization
                <input
                  className="input-width-12"
                  type="text"
                  name="organization"
                  value={formData.organization}
                  onChange={onFormDataChange}
                  placeholder="Enter your organization name"
                />
              </label>
            </div>

            <div className="input-width-12">
              <label>
                *You identify your organization as
                <Select
                  name="organizationIdentity"
                  // value={this.state.positionLevel}
                  // onSelect={this.handleSelect}
                  // options={this.positionOptions}
                  onSelect={onFormDataSelect}
                  options={[
                    {
                      value: "Student Group",
                      label: "Student Group",
                    },
                    {
                      value: "Company",
                      label: "Company",
                    },
                  ]}
                  placeholder="Please select one below"
                />
              </label>
            </div>

            <div className="subscribe-checkbox input-width-12">
              <label className="checkbox">
                <input
                  name="subscribe"
                  type="checkbox"
                  // defaultChecked={formData.subscribe}
                  value={formData.subscribe}
                  onChange={onCheckboxChange}
                />
                <span className="checkmark"></span>
                Subscribe to HackHub’s marketing emails
              </label>
            </div>

            <div className="term-policy input-width-12">
              By clicking on “Get a Demo” below, you agree to the HackHub &nbsp;
              <span>
                <a href="/">Terms of Use & Privacy Policy</a>
              </span>
            </div>
          </div>

          <div className="center-btn">
            {submitting ? (
              <button className="btn loading-btn" disabled>
                Sending...
              </button>
            ) : (
              <button className="btn" onClick={handleSubmit}>
                Get a Quote
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
