import React, { useEffect, useState } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";

const products = [
  {
    title: "HackHub Talent",
    path: "/talent",
    cName: "dropdown-link",
    key: "hackhub-talent",
  },
  {
    title: "HackHub Event",
    path: "/event",
    cName: "dropdown-link",
    key: "hackhub-event",
  },
  {
    title: "Summer Series",
    path: "http://www.summerseries.hackhub.com/",
    cName: "dropdown-link",
    key: "hackhub-summerseries",
  }
];

function NavBar(props) {
  const { btnOnClick } = props;

  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [demoBtnHover, setDemoBtnHover] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  // const [modalVisible, setModalVisible] = useState(false);

  const breakPoint = 860;

  const toggleShowDropdown = () => setShowDropdown(!showDropdown);

  const demoBtnRender = (hover) => (
    <button
      className={hover ? "btn sm" : "btn sm ghost"}
      onMouseEnter={() => setDemoBtnHover(true)}
      onMouseLeave={() => setDemoBtnHover(false)}
      onScroll={(p1, p2, p3) => {
        console.log("scroll", p1, p2, p3);
      }}
      onClick={
        btnOnClick
        // () => {
        //   setModalVisible(true);
        // }
      }
    >
      Get a Demo
    </button>
  );

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    const handleWindowScrolling = () => {
      setDemoBtnHover(true);
      clearTimeout();
      setTimeout(() => {
        if (window.scrollY < 50){
          setDemoBtnHover(false);
        } else {
          setDemoBtnHover(true)
        }
      }, 600);
    };
    window.addEventListener("scroll", handleWindowScrolling);
    return () => window.removeEventListener("scroll", handleWindowScrolling);
  }, []);

  return (
    <>
      <div className="navbar">
        <div className="container-1">
          <div className="row align-items-center">
            <div className="navbar-left-width">
              <Link to="/">
                {/* <img src={logo} className="nav-logo" alt="HackHub" /> */}
                <div className="nav-logo-div"></div>
              </Link>
            </div>

            {width < breakPoint ? (
              <div className="navbar-options navbar-right-width">
                <div
                  className="navbar-options-icon"
                  onClick={toggleShowDropdown}
                ></div>
              </div>
            ) : (
              <div className="navbar-options navbar-right-width">
                <div className="navbar-option-item">
                  {demoBtnRender(demoBtnHover)}
                </div>
                {products.map((element, index) => (
                  <div
                    key={`nav-bar-option${index}-to-${element.key}`}
                    className="navbar-option-item navbar-product-option"
                    onClick={() => {
                      if (element.path.startsWith('http')) {
                        window.open(element.path, '_blank');
                      } else {
                        navigate(element.path);
                      }
                    }}
                  >
                    <div className="hover-pointer">
                      {element.title}
                      </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {showDropdown && width < breakPoint && (
            <div className="mobile-dropdown">
              <ul>
                {products.map((element, index) => (
                  <li key={`nav-bar-option${index}-mobile-to-${element.key}`}>
                    <div
                      className="navbar-option-item navbar-product-option hover-pointer"
                      onClick={() => {
                        if (element.path.startsWith('http')) {
                          window.open(element.path, '_blank');
                        } else {
                          navigate(element.path);
                        }
                      }}
                    >
                      {element.title}
                    </div>
                  </li>
                ))}
                <li>
                  <div className="navbar-option-item">
                    {demoBtnRender(demoBtnHover)}
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div style={{ minHeight: 83 }}></div>
    </>
  );
}

export default NavBar;
