import "react-slideshow-image/dist/styles.css";
import "./SuccessStories.css";
import React, { useRef } from "react";
import { Fade } from "react-slideshow-image";
import { useNavigate } from "react-router-dom";

const successStoriesData = [
  {
    imgPath: "/images/xd_img_1.png",
    logoPath: "/images/xd_logo_2.png",
    logoSize: {
      height: 93,
      width: 83,
    },
    message:
      "HackHub was able to help us host a greatly successful event for over 140 highschool students. With its unique features for sending emails, organizing teams, and extensive integration with GitHub and Google Drive, our participants had an easy time hacking. The extensive tech support during the event was also a great help, being able to reach out whenever we needed was a huge plus.",
    author: "-Yoonsik Jung",
    company: "Xd Hacks",
  },
  {
    imgPath: "/images/norcal_img_1.png",
    logoPath: "/images/norcal_logo_2.png",
    logoSize: {
      height: 74,
      width: 131,
    },
    message:
      "HackHub is an amazing platform for both organizers and attendees to create projects and hackathons like NorcalHacks. There is a simplicity in using HackHub but it also comes with robust features and seamlessly combines everything you need into one platform. HackHub is your go-to for an organized event that’s comfortable for everyone to use.",
    author: "-Bhavya Gupta",
    company: "Norcal Hacks",
  },
  {
    imgPath: "/images/eduhacks_img_1.png",
    logoPath: "/images/eduhacks_logo_2.png",
    logoSize: {
      height: 55,
      width: 142,
    },
    message:
      "EduHacks works towards motivating and helping young programmers learn and hone their computer science skills. Since 2017, it has attracted attention from people across the globe, with more than 5,000 participants in the competition. Event partners in the past included Adobe, Microsoft GitHub and many more, as well as more than 10 world-renown schools, including the University of British Columbia.",
    author: "-Yan Hong",
    company: "Edu Hacks",
  },
];

function SuccessStories() {
  const slideRef = useRef();
  const navigate = useNavigate();

  const indicatorsRender = (activeKey) => (
    <div className="indicator-dot-row">
      {successStoriesData.map((_, index) => (
        <div
          key={`indicator-active-${activeKey}-key-${index}`}
          className={`indicator-dot ${activeKey === index ? "active" : ""}`}
          onClick={() => slideRef.current.goTo(index)}
        ></div>
      ))}
    </div>
  );
  return (
    <div className="container-1">
      <div className="event-success-stories">
        <h1 className="title">Some of Our Success Stories</h1>
        <div className="event-success-stories-slides">
          <Fade ref={slideRef} easing="ease" transitionDuration={500} arrows={false} cssClass="asdasd">
            {successStoriesData.map((data, index) => (
              <div className="slide-item" key={`success-story-key-${index}`}>
                <div className="slide-item-content">
                  <div className="slide-item-content-indicators">
                    {indicatorsRender(index)}
                  </div>
                  <p className="paragraph slide-item-content-message">
                    {data.message}
                  </p>
                  <div className="slide-item-content-footer">
                    <p className="paragraph">
                      {data.author}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {data.company}
                    </p>
                  </div>
                  <div className="slide-item-logo">
                    <img
                      style={{ ...data.logoSize }}
                      src={data.logoPath}
                      alt={`${data.company} logo`}
                    />
                  </div>
                </div>
                <div className="slide-item-image">
                  <img src={data.imgPath} alt={`${data.company} event`} />
                </div>
              </div>
            ))}
          </Fade>
        </div>
        <button
          className="btn"
          onClick={() => {
            // window.location.href = "/event/demo";
            navigate("/event/demo");
          }}
        >
          Get a Demo
        </button>
      </div>
    </div>
  );
}

export default SuccessStories;
