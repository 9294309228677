import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "./index.css";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar";
import Introduction from "./components/Introduction";
import Partners from "./components/Partners";
import HacktonIntro from "./components/HacktonIntro";
import Features from "./components/Features";
import Offers from "./components/Offers";
import SuccessStories from "./components/SuccessStories";

function EventPage() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>
          Learn, Share, Connect | HackHub Event
        </title>
        <meta property='og:site_name' content='HackHub Event' />
        <meta property="og:description" content="HackHub is an all-in-one online management platform that streamlines hackathon coordination, registration, collaboration and monetization." />
      </Helmet>
      <NavBar
        btnOnClick={() => {
          navigate("/event/demo");
        }}
      />
      <Introduction />
      <Partners />
      <HacktonIntro />
      <Features />
      <Offers />
      <SuccessStories />
      <Footer />
    </>
  );
}

export default EventPage;
