function Description() {
  return (
    <div className="company-description footer-width-col-4">
      <img
        src="/images/hackhub_logo_white.svg"
        className="description-header-logo hover-pointer"
        alt="HackHub"
        onClick={() => {
          window.location.href = "/";
        }}
      />
      <p>
        HackHub is dedicated to collaborating with our partners who help us
        develop our content and hire many of our program graduates.
      </p>
    </div>
  );
}

export default Description;
