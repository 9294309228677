import "./HacktonIntro.css";
import React from "react";

function HacktonIntro() {
  return (
    <div className="dark-background">
      <div className="container-1">
        <div className="event-row event-intro-container-2">
          <div className="event-intro">
            <h1 className="title">Create and Manage Hackathon Events</h1>
            <p className="paragraph">
              HackHub Event works with industry experts, leading companies,
              educational institutions and incubators to elevate individuals'
              creativity. Through online and offline hackathon events, HackHub
              provides the ultimate platform that reinforces the professional
              growth for companies and talent.
            </p>
          </div>

          <div className="intro-image">
            <img
              src="/images/hackton_dashboard_2.png"
              alt="hackton dashboard"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HacktonIntro;
