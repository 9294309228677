import "./Body.css";
import { useNavigate } from "react-router-dom";

function Body() {
  const navigate = useNavigate();

  return (
    <div className="container-1">
      <div className="thank-you-body-container">
        <div className="thank-you-body-img">
          <img
            src="/images/thank_you_icon.svg"
            alt="presentation HackHub Talent"
          />
        </div>
        <div className="thank-you-body">
          <h1 className="title">
            Thanks for signing up for a demo of HackHub product.
          </h1>
          <p className="paragraph">
            Our sales team will be in touch with you shortly.
          </p>
          <button
            className="btn"
            onClick={() => {
              navigate("/");
            }}
          >
            Back to Homepage
          </button>
        </div>
      </div>
    </div>
  );
}

export default Body;
