import "./Footer.css";
import Description from "./Description";
import twitter from "../../img/twitter.svg";
import facebook from "../../img/facebook.svg";
import linkedin from "../../img/linkedin.svg";
import instagram from "../../img/instagram.svg";
import discord from '../../img/discord.svg';

function Footer() {
  return (
    <div className="footer">
      <div className="container row-wrap footer-main">
        <Description />

        <div>
          <h2>Products</h2>
          <p><a href="/event">HackHub Event</a></p>
          <p><a href="/talent">HackHub Talent</a></p>
        </div>

        <div>
          <h2>Company</h2>
          <p><a href="/">Home</a></p>
          {/* <p><a href="/">About</a></p> */}
        </div>
        
        <div>
          <h2>Contact</h2>
          <p><a href="mailto:info@hackhub.com" target="_blank" rel="noopener noreferrer">
            info@hackhub.com
          </a></p>
          <p>#1200 555 W Hastings St</p>
          <p>Vancouver, BC V6B 4N6</p>
          <div className="row contact-logos">
            <a href="https://www.linkedin.com/company/hackhub">
              <img src={linkedin} className="contact-logo" alt="LinkedIn"/>
            </a>
            <a href="https://twitter.com/hackhubio/">
              <img src={twitter} className="contact-logo" alt="Twitter"/>
            </a>
            <a href="https://www.facebook.com/hackhubio/">
              <img src={facebook} className="contact-logo" alt="Facebook"/>
            </a>
            <a href="https://www.instagram.com/hackhub.io/">
              <img src={instagram} className="contact-logo" alt="Instagram"/>
            </a>
            <a href="https://discord.gg/PGB33TSFN6">
              <img src={discord} className="contact-logo" alt="Discord"/>
            </a>
          </div>
        </div>
      </div>

      <div className="container copyright">
        Copyright© 2016-2023 Inverse Technology Inc.
      </div>
    </div>
  );
}

export default Footer;
