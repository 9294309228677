import "./Features.css";

import { useNavigate } from "react-router-dom";

function Features() {
  const navigate = useNavigate();

  return (
    <>
      <div className="container-1 event-feature-section">
        <div className="event-row event-intro-container-3">
          <div className="event-intro">
            <h1 className="title">All-in-One Hackathon Platform Solution</h1>
            <p className="paragraph">
              Private Event & Data Encryption, Participant Management, Judging
              System, QR Code Check In, Project Submission, Email & SMS
              Announcements, Social Media Integration, Live Streaming and many
              more — all in one place.
            </p>
          </div>

          <div
            className="intro-image"
            //  style={{filter: "drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.2))"}}
          >
            <img
              src="/images/event_feature_1.svg"
              alt="requirments filter dashboard"
            />
          </div>
        </div>

        <div className="event-row-reverse event-intro-container-4">
          <div className="event-intro">
            <h1 className="title">Featured Projects Showcase</h1>
            <p className="paragraph">
              Participants can upload their hackathon masterpiece onto HackHub
              as a place to showcase their portfolio to recruiters
            </p>
          </div>

          <div className="intro-image">
            <img
              src="/images/event_feature_2.svg"
              alt="filter weight dashboard"
            />
          </div>
        </div>

        <div className="event-row event-intro-container-5">
          <div className="event-intro">
            <h1 className="title">Multi-round & Easy Judging System</h1>
            <p className="paragraph">
              We created a separate judging interface to made submission review
              and scoring easy.
            </p>
          </div>

          <div className="intro-image">
            <img
              src="/images/event_feature_3.svg"
              alt="filter weight dashboard"
            />
          </div>
        </div>

        <div className="text-align-center ">
          <button
            className="btn"
            onClick={() => {
              // window.location.href="/event/demo";
              navigate("/event/demo");
            }}
          >
            Get a Free Demo
          </button>
        </div>
      </div>
    </>
  );
}

export default Features;
