import "./Features.css";

function Features() {
  return (
    <div className="feature-background-2">
      <div className="container-1">
        <div className="talent-features">
          <h1 className="title">How HackHub Helps You Find Talents</h1>
          <div className="feature-items-row">
            <div className="feature-item">
              <div className="item-icon">
                <img src="/images/icon_profile.svg" alt="talent"></img>
              </div>
              <h1 className="title">Specialized Tech Talents</h1>
              <p className="paragraph">
                We focus on recruiting tech talent with expertise from software
                development, game development, machine learning, artificial
                intelligence, to graphic design and UIUX design.
              </p>
            </div>

            <div className="feature-item">
              <div className="item-icon">
                <img src="/images/icon_project.svg" alt="project"></img>
              </div>
              <h1 className="title">Project - Ready Talent Pool</h1>
              <p className="paragraph">
                Our talent pool contains professionals with proven skills and
                projects in hackathons. Solving complex and practical challenges
                is the #1 quality we value.
              </p>
            </div>

            <div className="feature-item">
              <div className="item-icon">
                <img src="/images/icon_job.svg" alt="job"></img>
              </div>
              <h1 className="title">Online Job Board</h1>
              <p className="paragraph">
                We provide employers with access to our online job board.
                Professionals from a variety of industries can be found here as
                they continue to grow and evolve to showcase new skills and
                ideas.
              </p>
            </div>

            <div className="feature-item">
              <div className="item-icon">
                <img src="/images/icon_ai.svg" alt="AI"></img>
              </div>
              <h1 className="title">Fast and Accurate AI Matching</h1>
              <p className="paragraph">
                Our AI technology matches your hiring needs with our diverse
                talent pool with just one click. Simply input your preference on
                Candidates qualifications and let our AI do the rest for you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
