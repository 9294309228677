import React from "react";
import DemoForm from "../DemoForm";
import "./index.css";

export default function DemoUpper(props) {
  const {
    title,
    subtitle,
    paragraph,
    subtitleColor,
    iconPath,
    iconAlt,
    bottomMargin = 0,
  } = props;
  const { showProduct = false, eventDemo = false, talentDemo = false } = props;

  return (
    <>
      <div className="feature-background-4">
        <div className="container-1 demo-upper-top">
          <div className="left">
            <div className="demo-title">{title}</div>
            <div className="demo-subtitle-row">
              <div
                className="demo-subtitle"
                style={{ backgroundImage: subtitleColor }}
              >
                {subtitle}
              </div>
              <div className="demo-icon">
                <img src={iconPath} alt={iconAlt} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-1 demo-upper-bot">
        <div className="demo-form-container">
          <DemoForm
            showProduct={showProduct}
            eventDemo={eventDemo}
            talentDemo={talentDemo}
          />
        </div>
        <div className="left">
          <div className="demo-paragraph">{paragraph}</div>
        </div>
      </div>

      <div style={{ height: bottomMargin }}></div>
    </>
  );
}
