import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
// import App from './App';
import reportWebVitals from "./reportWebVitals";
import HomePage from "./pages/HomePage";
import TalentPage from "./pages/TalentPage";
import EventPage from "./pages/EventPage";
import TalentDemoPage from "./pages/TalentDemoPage";
import EventDemoPage from "./pages/EventDemoPage";
import DemoThankyou from "./pages/ThankYouPage/Demo";
import ScrollToTop from "./components/CustomHooks/ScrollToTop";

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/talent" element={<TalentPage />} />
        <Route path="/talent/demo" element={<TalentDemoPage />} />
        <Route path="/event" element={<EventPage />} />
        <Route path="/event/demo" element={<EventDemoPage />} />
        <Route path="/thankyou/demo" element={<DemoThankyou />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
