import React, { useState } from "react";
import QuoteModalForm from "../../../components/QuoteModalForm";
import "./Pricing.css";

export default function Pricing(props) {
  const [visible, setVisible] = useState(false);

  const prices = [
    {
      title: "start",
      titleClass: "",
      priceNumberLeft: true,
      priceNumber: "$0.25",
      priceText: "/User",
      subTitle: "Highlighted features:",
      services: [
        {
          vector: true,
          content: "Application Screening",
        },
        {
          vector: true,
          content: "Team Formation",
        },
        {
          vector: true,
          content: "Project Submission",
        },
        {
          vector: true,
          content: "Judging System",
        },
        {
          vector: true,
          content: "Livestream",
        },
      ],
    },

    {
      title: "Premium",
      titleClass: "featured",
      priceNumber: "$6500",
      priceText: "Starts from",
      subTitle: "Service includes:",
      services: [
        {
          vector: true,
          content: "Unlimited Platform Package",
        },
        {
          vector: true,
          content: "Designated Tech Support",
        },
        {
          vector: true,
          content: "Event Website using HackHub Website Creator",
        },
        {
          vector: true,
          content: "Event Infographics",
        },
        {
          vector: true,
          content: "Presentation Template",
        },
        {
          vector: true,
          content: "Social Media Template",
        },
      ],
    },
    {
      title: "Enterprise",
      titleClass: "featured",
      priceNumber: "",
      priceText: "Contact Sales",
      subTitle: "Service includes:",
      
      services: [
        {
          vector: true,
          content: "Event Planning and Faciliation",
        },
        {
          vector: true,
          content: "Community Engagaement",
        },
        {
          vector: true,
          content: "Event Marketing",
        },
        {
          vector: true,
          content: "Sponsor Benefits",
        },
        {
          vector: false,
          content: "And more..",
        },
      ],

      button: (
        <button className="btn" onClick={() => setVisible(true)}>
          Get a Quote
        </button>
      ),
    },
  ];
  return (
    <div className="container-1">
      <QuoteModalForm visible={visible} handleVisible={setVisible} />
      <div className="event-pricing">
        <h1 className="title">HackHub Event Pricing</h1>
        <div className="event-pricing-items-row">
          {/* <div className="event-pricing-item">
            <div className="title"> start </div>
            <div className="pricing">
              <div className="number">$0.25</div>
              <div className="text">/user</div>
            </div>
            <div className="sub-title">Highlighted features:</div>
            <div className="service-item">
              <div className="vector">
                <img src="/images/vector.svg" alt="" />
              </div>
              <div className="paragraph">Application screening</div>
            </div>
          </div> */}
          {prices.map((price, index) => (
            <div key={`price-item-${index}`} className="event-pricing-item">
              <h2 className={"title " + price.titleClass}> {price.title} </h2>
              {/* <div className="pricing">
                {price.priceNumberLeft && (
                  <div className="number">{price.priceNumber}</div>
                )}
                <div className="text">{price.priceText}</div>
                {!price.priceNumberLeft && (
                  <div className="number">{price.priceNumber}</div>
                )}
              </div> */}
              <div className="services">
                <h3 className="sub-title">{price.subTitle}</h3>
                {price.services.map((service, sIndex) => (
                  <div
                    className="service-item"
                    key={`price-item-${index}-service-${sIndex}`}
                  >
                    <div className="vector">
                      {service.vector && (
                        <img src="/images/vector.svg" alt="" />
                      )}
                    </div>
                    <p className="paragraph">{service.content}</p>
                  </div>
                ))}
              </div>
              {price.button && (
                <div className="price-item-btn"> {price.button} </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
