import "./Introduction.css";
import { useNavigate } from "react-router-dom";

function Introduction() {
  const navigate = useNavigate();

  return (
    <>
      <div className="container-1">
        <div className="talent-row-reverse talent-intro-container-1">
          <div className="talent-intro">
            <h1 className="title">HackHub Talent</h1>
            <h3 className="sub-title">AI Job Board for Business</h3>
            <p className="paragraph">
              Our AI-driven tech talent job board matches you with the perfect
              candidates based on job qualifications and requirements.
            </p>
            <button
              className="btn md"
              onClick={() => {
                // window.location.href = "/talent/demo";
                navigate("/talent/demo");
              }}
            >
              Get a Demo
            </button>
          </div>

          <div className="intro-image">
            <img src="/images/resume_pic.svg" alt="resume dashboard" />
          </div>
        </div>
      </div>

      <div className="dark-background">
        <div className="container-1">
          <div className="talent-row talent-intro-container-2">
            <div className="talent-intro">
              <h1 className="title">
                Get Qualified Candidates Quickly and Easily
              </h1>
              <p className="paragraph">
                Our programmers and designer candidates are pre-screened and well qualified so help businessessave time and money in their recruitment efforts. 
                Whether it is website development, mobile app design, server-side programming or interface design, we have you covered.
              </p>
            </div>

            <div className="intro-image">
              <img
                src="/images/talent_dashboard_2.png"
                alt="talent dashboard"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-1 talent-intro-feature-section">
        <div className="talent-row talent-intro-container-3">
          <div className="talent-intro">
            <h1 className="title">
              Filter Resumes Based on Customized Preferences
            </h1>
            <p className="paragraph">
              Based on your pre-set preferences, our AI system will auto-filter
              candidates and generate a comprehensive report on their
              qualifications and skill levels.
            </p>
          </div>

          <div className="intro-image">
            <img
              src="/images/talent_feature_1.svg"
              alt="requirments filter dashboard"
            />
          </div>
        </div>

        <div className="talent-row-reverse talent-intro-container-4">
          <div className="talent-intro">
            <h1 className="title">Visualize the Compatibility of Candidates</h1>
            <p className="paragraph">
              Without going through stacks of resumes, employers can simply
              adjust the skill weighting, setup their cut-off scores for each
              criteria, and let our AI do the rest.
            </p>
          </div>

          <div className="intro-image">
            <img
              src="/images/talent_feature_2.svg"
              alt="filter weight dashboard"
            />
          </div>
        </div>

        <div className="talent-row talent-intro-container-5">
          <div className="talent-intro">
            <h1 className="title">
              The Source-to-Hire Solution in Our IT Talent Community
            </h1>
            <p className="paragraph">
              Combined with HackHub event, we enable companies to connect with
              top developers and designers in the industry, to build and expand
              their teams with ease.
            </p>
          </div>

          <div className="intro-image">
            <img
              src="/images/talent_feature_3.svg"
              alt="filter weight dashboard"
            />
          </div>
        </div>

        <div className="text-align-center ">
          <button
            className="btn"
            onClick={() => {
              // window.location.href = "/talent/demo";
              navigate("/talent/demo");
            }}
          >
            Get a Demo
          </button>
        </div>
      </div>
    </>
  );
}

export default Introduction;
