import React from "react";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import "./Introduction.css";

function Introduction() {
  return (
    <div className="container-1">
      <div className="row home-row">
        <div className="home-intro">
          <h1 className="title intro-title">
            Learn, Share, Connect
          </h1>
          <p className="paragraph intro-body">
          Our products create an ecosystem where developers, 
          artists, investors, and entrepreneurs learn cutting-edge 
          technologies, share project experience, and connect with communities.
          </p>
          <button
            className="btn md"
            onClick={() => {
              elementScrollIntoView(document.querySelector(".form-block"), {
                behavior: "smooth",
                block: "start",
                inline: "start",
              });
              // setVisible(true);
            }}
          >
            Get a Free Demo
          </button>
        </div>

        <div className="home-intro-img">
          <img src="/images/event-dashboard-2.png" alt="event dashboard" />
        </div>
      </div>
    </div>
  );
}

export default Introduction;
