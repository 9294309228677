import React from "react";
import { Helmet } from "react-helmet";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer/Footer";
import DemoUpper from "../../components/DemoUpper";

export default function TalentDemoPage(props) {
  return (
    <div>
      <Helmet>
        <title>Get a Demo | HackHub Talent</title>
        <meta
          name="description"
          content="Through the Event and Talent platforms, HackHub gathers outstanding talents in the technology industry and builds bridges for enterprises and talents. HackHub efficiently matches job through AI Big Data."
        />
      </Helmet>
      <NavBar
        btnOnClick={() => {
          elementScrollIntoView(
            document.querySelector(
              ".demo-upper-bot .demo-form-container .demo-form .demo-form-scroll-ref"
            ),
            {
              behavior: "smooth",
              block: "start",
              inline: "start",
            }
          );
        }}
      />
      <DemoUpper
        talentDemo={true}
        title="Get a Free Demo of HackHub Talent"
        subtitle="AI Job Board for Business"
        subtitleColor="linear-gradient(90.15deg, #6473DB -0.06%, #6E46C6 142.27%)"
        paragraph="We take your professional needs and connect your business with experienced individuals from our expanding talent pool."
        iconPath="/images/demo_talent_icon.svg"
        iconAlt="hackhub talent demo icon"
        bottomMargin={129}
      />
      <Footer />
    </div>
  );
}
