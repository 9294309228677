import "./SupportedBy.css";

const alts = [
  "SFU VentureLabs",
  "Innovate BC",
  "SFU Innovates",
  "Venture for Canada",
  "Education and Resources",
  "Mitacs",
  "Export Development Canada (EDC)",
  "CanExport",
];

function SupportedBy() {
  return (
    // <div className="mask-group-1-background">
      <div className="container-1 partners">
        <div className="flex-column align-items-center">
          <h1 className="title">Proudly Supported By</h1>

          <div className="row align-items-center partner-logos">
            {Array(8)
              .fill()
              .map((_, index) => (
                <div key={index} className="partner-logo">
                  <img
                    src={`/images/partner${index + 1}.svg`}
                    alt={alts[index]}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    // </div>
  );
}

export default SupportedBy;
