import axios from "axios";
import { DOMAIN, ENDPOINT } from "./config";

export const emailSend = (data, handleSuccess, handleError) => {
  axios.post(`${DOMAIN}${ENDPOINT}`, {
    name: data.name.trim(),
    company: data.company.trim(),
    email: data.email.trim(),
    msg: data.msg,
  })
  .then(res => {
    if (res.data.status === 'success') { handleSuccess(); }
    else { handleError(); }
  })
  .catch(err => { handleError(); });
} 