import React, { useState } from "react";
import "./index.css";

import Select from "./components/MySelect";
import { formatDemoFormSendData, productsName, validateData } from "../../util";
import { emailSend } from "../../service";
import { useNavigate } from "react-router-dom";

const requiredFields = [
  "name",
  "email",
  "organization",
  "organizationIdentity",
];

export default function DemoForm(props) {
  const { showProduct = true, eventDemo = false, talentDemo = false } = props;

  const navigate = useNavigate();

  const initialFormData = {
    hackhub_event: eventDemo,
    hackhub_talent: talentDemo,
    name: "",
    email: "",
    organization: "",
    subscribe: false,
  };

  const [submitting, setSubmitting] = useState(false);

  const [formData, setFormdata] = useState(initialFormData);

  const onFormDataChange = (e) => {
    setFormdata({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onCheckboxChange = (e) => {
    setFormdata({
      ...formData,
      [e.target.name]: !formData[e.target.name],
    });
  };

  const onFormDataSelect = (option, action) => {
    setFormdata({
      ...formData,
      [action.name]: option.value,
    });
  };

  const onProductSelect = (index) => {
    let productKey = productsName[index].key;
    setFormdata({
      ...formData,
      [productKey]: !formData[productKey],
    });
  };

  const handleSubmit = () => {
    setSubmitting(true);
    if (validateData(formData, requiredFields)) {
      setSubmitting(false);
      emailSend(
        formatDemoFormSendData(formData),
        // success
        () => {
          alert("Thanks for your interests. We will contact you soon.");
          navigate("/");
          setFormdata(initialFormData); // COULD DELETE THIS LINE
          setSubmitting(false);
        },
        // fail or error
        () => {
          alert(
            "Woops. Something wrong. Please try to email or call us directly."
          );
          setSubmitting(false);
        }
      );
    } else {
      // not validated
      alert("Please fill all required fields.");
      setSubmitting(false);
    }
  };

  return (
    <div className="demo-form">
      <div className="demo-form-scroll-ref"></div>
      <div className="background-logo">
        <img src="/images/hackhub_logo_3.svg" alt="hackhub logo" />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return false;
        }}
        onKeyDown={(e) => {
          if (e.code === "Enter") e.preventDefault();
        }}
      >
        <div className="form-fields row">
          {showProduct && (
            <div className="input-width-12">
              <label>
                *Product
                <div className="form-product-options-row">
                  {productsName.map((product, index) => (
                    <div
                      key={index}
                      className={`product-option  + ${
                        formData[productsName[index].key] ? "selected" : ""
                      }`}
                      onClick={() => onProductSelect(index)}
                    >
                      {product.name}
                    </div>
                  ))}
                </div>
              </label>
            </div>
          )}

          <div className="input-width-12">
            <label>
              *Name
              <input
                className="input-width-12"
                type="text"
                name="name"
                value={formData.name}
                onChange={onFormDataChange}
                placeholder="Enter your name"
              />
            </label>
          </div>

          <div className="input-width-12">
            <label>
              *Email Address
              <input
                className="input-width-12"
                type="email"
                name="email"
                value={formData.email}
                onChange={onFormDataChange}
                placeholder="Enter your work email address"
              />
            </label>
          </div>

          <div className="input-width-12">
            <label>
              *Organization
              <input
                className="input-width-12"
                type="text"
                name="organization"
                value={formData.organization}
                onChange={onFormDataChange}
                placeholder="Enter your organization name"
              />
            </label>
          </div>

          <div className="input-width-12">
            <label>
              *You identify your organization as
              <Select
                name="organizationIdentity"
                onSelect={onFormDataSelect}
                options={[
                  {
                    value: "Student Group",
                    label: "Student Group",
                  },
                  {
                    value: "Company",
                    label: "Company",
                  },
                ]}
                placeholder="Please select one below"
              />
            </label>
          </div>

          <div className="subscribe-checkbox input-width-12">
            <label className="checkbox">
              <input
                name="subscribe"
                type="checkbox"
                value={formData.subscribe}
                onChange={onCheckboxChange}
              />
              <span className="checkmark"></span>
              Subscribe to HackHub’s marketing emails
            </label>
          </div>

          <div className="term-policy input-width-12">
            By clicking on “Get a Demo” below, you agree to the HackHub &nbsp;
            <span>
              <a href="/">Terms of Use & Privacy Policy</a>
            </span>
          </div>
        </div>

        <div className="center-btn">
          {submitting ? (
            <button className="btn loading-btn" disabled>
              Sending...
            </button>
          ) : (
            <button className="btn" onClick={handleSubmit}>
              Get a Demo
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
