import React from "react";
import Select from "react-select";

function MySelect (props) {
  const { name, value, onSelect, options, placeholder, required=false } = props;

  const placeholderStyle1 = {
    fontFamily: "Quicksand",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1rem",
    color: "#c9c9c9",
  };

  const optionFontStyle = {
    color: '#2D2D2D',
    // fontFamily: 'Lato',
    // fontSize: 14,
    // fontStyle: 'normal',
    // fontWeight: 500,
    // lineHeight: '17px',
    // letterSpace: 0,
    fontFamily: "Quicksand",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1rem",
    // color: "#c9c9c9",
  }


  return (
    <Select required={required}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onSelect}
      options={options}
      isSearchable={false}
      // menuIsOpen={true}
      styles={{
        height: 10,
        container: (styles) => ({
          ...styles,
          marginTop: 8,
          marginBottom: 16,
        }),
        control: (styles) => ({
          ...styles,
          backgroundColor: "white",
          // backgroundColor: "#EDEDED",
          borderRadius: 3,
          border: '1px solid #ededed',
          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
          height: 34,
          minHeight: 34,
        }),
        // dropdownIndicator: (styles) => ({
        //   ...styles,
        //   // backgroundImage: 'linear-gradient(15.41deg, #3983DB -57.11%, #6473DB 84.13%)',
        //   // backgroundClip: 'text',
        //   // WebkitBackgroundClip: 'text',
        //   // MozBackgroundClip: 'text',
        //   // WebkitTextFillColor: 'transparent',
        //   // MozTextFillColor: 'transparent',
        //   // color: 'black',

        //   // background:
        //   //   "-webkit-linear-gradient(180deg, #6473DB 0%, #3983DB 100%)",
        //   // WebkitBackgroundClip: "content-box",
        //   // WebkitTextFillColor: "transparent",
        // }),
        // indicatorsContainer: (styles) => ({
        //   ...styles,
        //   // color: 'black',
        // }),
        indicatorSeparator: (styles) => ({
          display: "none",
        }),
        menu: (styles) => ({
          ...styles,
          margin: '2px 0',
          borderRadius: 3,
        }),
        menuList: (styles) => ({
          ...styles,
          paddingTop: 7,
          paddingBottom: 7,
        }),
        option: (styles, { isFocused, isSelected }) => ({
          ...styles,
          ...optionFontStyle,
          backgroundColor: isFocused || isSelected ? '#F1F1F1' : 'white',
          height: 32,
        }),
        placeholder: (styles) => ({
          ...styles,
          ...placeholderStyle1,
        }),
        singleValue: (styles, state) => { 
          // console.log(styles)
          // console.log(state)
          return ({
          ...styles,
          ...optionFontStyle
        })},
        valueContainer: (styles) => ({
          ...styles,
          ...optionFontStyle
        }),
      }}
    />
  );
}

export default MySelect;
