import "./Offers.css";
import React from "react";

const offerImg = [
  {
    path: "/images/event_offer_icon_1.svg",
    name: "Event homepage builder",
  },
  {
    path: "/images/event_offer_icon_2.svg",
    name: "Ticket collection",
  },
  {
    path: "/images/event_offer_icon_3.svg",
    name: "Participant screening",
  },
  {
    path: "/images/event_offer_icon_4.svg",
    name: "Online project submission",
  },
  {
    path: "/images/event_offer_icon_5.svg",
    name: "Mobile judging",
  },
  {
    path: "/images/event_offer_icon_6.svg",
    name: "Event technical support",
  },
];

function Offers() {
  return (
    <div className="feature-background-3">
      <div className="container-1 event-offers-section">
        <h1 className="title">What Can We Offer</h1>
        <div className="row align-items-center event-offer-items">
          {offerImg.map((e, index) => (
            <div className="event-offer-item" key={index}>
            <div className="item-icon">
              <img src={e.path} alt={e.name} />
            </div>
            <h3 className="sub-title">{e.name}</h3>
          </div>
          )
          )}
          
        </div>
      </div>
    </div>
  );
}

export default Offers;
