import React from "react";
import { Helmet } from "react-helmet";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer/Footer";
import DemoUpper from "../../components/DemoUpper";
import Pricing from "./components/Pricing";

export default function EventDemoPage(props) {
  return (
    <div>
      <Helmet>
        <title>Get a Demo | HackHub Event</title>
        <meta
          name="description"
          content="Through the Event and Talent platforms, HackHub gathers outstanding talents in the technology industry and builds bridges for enterprises and talents. HackHub efficiently matches job through AI Big Data."
        />
      </Helmet>
      <NavBar
        btnOnClick={() => {
          elementScrollIntoView(
            document.querySelector(
              ".demo-upper-bot .demo-form-container .demo-form .demo-form-scroll-ref"
            ),
            {
              behavior: "smooth",
              block: "start",
              inline: "start",
            }
          );
        }}
      />
      <DemoUpper
        eventDemo={true}
        title="Get a Free Demo on HackHub Event"
        subtitle="all-in-one hackathon management system"
        subtitleColor="linear-gradient(90.31deg, #48B6CA 29.98%, #0094FB 91.64%)"
        paragraph="HackHub is an all-in-one management platform that delivers boundless hackathon experience to developers and designers worldwide."
        iconPath="/images/demo_event_icon.svg"
        iconAlt="hackhub event demo icon"
      />
      <Pricing />
      <Footer />
    </div>
  );
}
