export const productsName = [
  {
    name: "HackHub Event",
    key: "hackhub_event",
  }, 
  {
    name: "HackHub Talent",
    key: "hackhub_talent",
  }
];

export const formatDemoFormSendData = (formData) => {
  let demoTypesStr = "";
  for (let i = 0; i < productsName.length; i++) {
    if (formData[productsName[i].key]) {
      demoTypesStr += productsName[i].name;
      if (i !== productsName.length) {
        demoTypesStr += ", ";
      }
    }
  }

  return {
    name: formData.name,
    email: formData.email,
    company: `Organization type: ${formData.organizationIdentity}\nOrganization name: ${formData.organization}`,
    msg: `This is a DEMO request for ${demoTypesStr}. And subscribes is ${
      formData.subscribe ? "checked" : "unchecked"
    }.`,
  };
};

export const formatQuoteFormSendData = (formData) => {
  return {
    name: formData.name,
    email: formData.email,
    company: `${formData.organizationIdentity}-${formData.organization}`,
    msg: `This is a EVENT QUOTE request for [${productsName[0].name}]. And subscribes is [${
      formData.subscribe ? "checked" : "Unchecked"
    }]. Followings are raw data: ${JSON.stringify(formData)}`,
  };
};

export const validateData = (data, requiredFields=[]) => {
  // console.log(data);
  const currKeys = Object.entries(data).filter(([key, value]) => {
    // console.log(key, value);
    return requiredFields.includes(key) 
      && (value !== null && value !== undefined && value !== "");
  });
  // console.log(currKeys);
  return currKeys.length === requiredFields.length;
};