import "./Partners.css";
import React from "react";

const alts = [
  "SFU VentureLabs",
  "Innovate BC",
  "SFU Innovates",
  "Venture for Canada",
];

function Partners() {
  return (
    <div className="container-1 event-partners flex-column align-items-center">
      <p className="paragraph">
        100+ organizations have used Hackhub for amazing hackathon events
      </p>
      <div className="row align-items-center event-partner-logos">
            {Array(4)
              .fill()
              .map((_, index) => (
                <div key={index} className="event-partner-logo">
                  <img
                    src={`/images/partner${index + 1}.svg`}
                    alt={alts[index]}
                  />
                </div>
              ))}
          </div>
    </div>
  );
}

export default Partners;
