import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import NavBar from "../../../components/NavBar";
import Body from "./components/Body";
import "./index.css";

function DemoThankyou() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>
          Learn, Share, Connect | HackHub Talent
        </title>
      </Helmet>
      <NavBar
        btnOnClick={() => {
          navigate("/");
        }}
      />
      <Body />
      <Footer />
    </>
  );
}

export default DemoThankyou;
