import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "./index.css";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar";
import TalentFooter from "./components/TalentFooter";
import Features from "./components/Features";
import Introduction from "./components/Introduction";

function TalentPage() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>
          Learn, Share, Connect | HackHub Talent
        </title>
        <meta property='og:site_name' content='HackHub Talent' />
        <meta property="og:description" content="Our AI-driven tech talent job board matches you with the perfect candidates base on job qualifications and requirements." />
      </Helmet>
      <NavBar
        btnOnClick={() => {
          navigate("/talent/demo");
        }}
      />
      <Introduction />
      <Features />
      <TalentFooter />
      <Footer />
    </>
  );
}

export default TalentPage;
