import "./Introduction2.css";

function Introduction2() {
  return (
    <div className="feature-background">
      <div className="container-1 introduction2">
        <div className="title">Creative Teamwork Made Easy</div>
        <div className="paragraph">
          HackHub Event works with industry experts, leading companies,
          educational institutions and incubators to elevate individuals’
          creativity. Through online and offline hackathon events, HackHub
          provides the ultimate platform that reinforces the professional growth
          for companies and talents.
        </div>
      </div>
    </div>
  );
}

export default Introduction2;
