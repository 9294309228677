import "./FormBlock.css";
import CountUp from "react-countup";
import DemoForm from "../../../components/DemoForm";

const duration = 2.5;

function FormBlock() {
  return (
    <div className="container-1">
      <div className="row form-block">
        <div className="form-block-left">
          <div className="impact-item-2">
            <div className="impact-icon">
              <img
                style={{ height: 100, width: 100 }}
                src="/images/hacker-icon.png"
                alt="hacker"
              />
            </div>
            <div className="impact-content">
              <div className="impact-number">
                <CountUp end={160000} duration={duration} separator="," />+
              </div>
              <div className="impact-title">Partipants</div>
            </div>
          </div>

          <div className="impact-item-2">
            <div className="impact-icon">
              <img
                style={{ width: 68, height: 76 }}
                src="/images/tech-icon.png"
                alt="tech"
              />
            </div>
            <div className="impact-content">
              <div className="impact-number">
                <CountUp end={20} duration={duration} separator="," />+
              </div>
              <div className="impact-title">Tech Stacks</div>
            </div>
          </div>

          <div className="impact-item-2">
            <div style={{ height: 127 }} className="impact-icon">
              <img
                style={{ width: 112, height: 112 }}
                src="/images/mac-icon.png"
                alt="mac"
              />
            </div>
            <div className="impact-content">
              <div className="impact-number">
                <CountUp end={64} duration={duration} separator="," />+
              </div>
              <div className="impact-title">Enterprise Partners</div>
            </div>
          </div>
        </div>
        <div className="form-block-right">
          <DemoForm />
        </div>
      </div>
    </div>
  );
}

export default FormBlock;
