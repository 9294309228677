import "./Products.css";
import React from "react";
import { useNavigate } from "react-router-dom";

function Products() {

  const navigate = useNavigate();

  return (
    <div className="container-1 products flex-column align-items-center">
      <div className="products-sub-title">
        <span>OUR PRODUCTS HELP COMPANIES</span>
      </div>
      <div className="products-title">
        <span>Connect And Grow With The Right People</span>
      </div>
      <div className="row align-items-center product-items">

        <div className="product-item" 
          onClick={() => {
            navigate("/event");
          }}
        >
          <div className="product-card product-card-style1">
            <div className="product-item-img">
              <img style={{height: "110%"}} src="/images/feature_event_2.png" alt="hackhub event" />
            </div>
            <div className="product-card-body">
              <h2>HackHub <span>Event</span></h2>
              <p>An all-in-one management platform that delivers boundless hackathon experience to developers and designers worldwide.</p>
            </div>
          </div>
        </div>

        <div className="product-item" 
          onClick={() => {
            navigate("/talent");
          }}
        >
          <div className="product-card product-card-style2">
            <div className="product-item-img">
              <img style={{height: "100%"}} src="/images/feature_talent_2.png" alt="hackhub talent" />
            </div>
            <div className="product-card-body">
              <h2>HackHub <span>Talent</span></h2>
              <p>An AI-driven tech talent hiring platform that matches you with the perfect candidates base on qualifications and requirements.</p>
            </div>
          </div>
        </div>
        
        <div className="product-item"
          onClick={() => { window.location.href = 'https://www.hackhub.io/' }}
        >
          <div className="product-card product-card-style3">
            <div className="product-item-img" style={{marginLeft:"40px"}}>
              <img style={{height: "90%"}} src="/images/feature_kids.png" alt="hackhub kids" />
            </div>
            <div className="product-card-body">
              <h2>HackHub <span>Kids</span></h2>
              <p>Choose HackHub Kids for our strong focus on STEAM Education, encapsulated by our slogan, "Unleash Young Innovators."</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Products;
