import "./Introduction.css";
import React from "react";
import { useNavigate } from "react-router-dom";

function Introduction() {
  const navigate = useNavigate();

  return (
    <div className="container-1">
      <div className="event-row-reverse event-intro-container-1">
        <div className="event-intro">
          <h1 className="title">HackHub Event</h1>
          <h3 className="sub-title">
            all-in-one hackathon management system
          </h3>
          <p className="paragraph">
          HackHub is an all-in-one online management platform that streamlines hackathon coordination, registration, collaboration and monetization.
          </p>
          <button
            className="btn md"
            onClick={() => {
              // window.location.href="/event/demo";
              navigate("/event/demo");
            }}
          >
            Get a Demo
          </button>
        </div>

        <div className="intro-image">
          <img src="/images/event_pic_1.png" alt="event dashboard" />
        </div>
      </div>
    </div>
  );
}

export default Introduction;
